import { create } from 'zustand';
import { Newsletter, NewsletterFilters, NewsletterStatus, NewsletterTemplate } from '../types/newsletter';

interface NewsletterState {
  newsletters: Newsletter[];
  filters: NewsletterFilters;
  selectedNewsletter: Newsletter | null;
  loading: boolean;
  error: string | null;
  setFilters: (filters: Partial<NewsletterFilters>) => void;
  setSelectedNewsletter: (newsletter: Newsletter | null) => void;
  fetchNewsletters: () => Promise<void>;
  createNewsletter: (data: Partial<Newsletter>) => Promise<void>;
  updateNewsletter: (id: string, data: Partial<Newsletter>) => Promise<void>;
  deleteNewsletter: (id: string) => Promise<void>;
  scheduleNewsletter: (id: string, date: string) => Promise<void>;
  sendNewsletter: (id: string) => Promise<void>;
}

export const useNewsletterStore = create<NewsletterState>((set, get) => ({
  newsletters: [],
  filters: {
    status: 'all',
    template: 'all',
    search: '',
    dateRange: {}
  },
  selectedNewsletter: null,
  loading: false,
  error: null,

  setFilters: (newFilters) =>
    set((state) => ({
      filters: { ...state.filters, ...newFilters }
    })),

  setSelectedNewsletter: (newsletter) =>
    set({ selectedNewsletter: newsletter }),

  fetchNewsletters: async () => {
    set({ loading: true, error: null });
    try {
      // Simulated API call
      await new Promise(resolve => setTimeout(resolve, 1000));
      
      const newsletters: Newsletter[] = [
        {
          id: '1',
          title: 'Monthly Practice Update - March 2024',
          subject: 'Your Dental Practice Newsletter',
          content: '<p>Dear valued patients...</p>',
          template: 'monthly-update',
          status: 'scheduled',
          createdAt: new Date().toISOString(),
          updatedAt: new Date().toISOString(),
          scheduledFor: new Date(Date.now() + 86400000).toISOString(),
          author: {
            id: '1',
            name: 'Dr. Sarah Johnson',
            avatar: 'https://ui-avatars.com/api/?name=Sarah+Johnson'
          },
          recipients: {
            groups: ['active-patients', 'referring-doctors'],
            count: 250
          },
          stats: {
            opened: 0,
            clicked: 0,
            bounced: 0
          }
        }
      ];

      set({ newsletters, loading: false });
    } catch (error) {
      set({ error: 'Failed to fetch newsletters', loading: false });
    }
  },

  createNewsletter: async (data) => {
    set({ loading: true, error: null });
    try {
      // Simulated API call
      await new Promise(resolve => setTimeout(resolve, 1000));
      
      const newNewsletter: Newsletter = {
        id: Date.now().toString(),
        title: data.title || '',
        subject: data.subject || '',
        content: data.content || '',
        template: data.template,
        status: 'draft',
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString(),
        author: {
          id: '1',
          name: 'Dr. Sarah Johnson',
          avatar: 'https://ui-avatars.com/api/?name=Sarah+Johnson'
        },
        recipients: data.recipients || { groups: [], count: 0 }
      };

      set((state) => ({
        newsletters: [...state.newsletters, newNewsletter],
        loading: false
      }));
    } catch (error) {
      set({ error: 'Failed to create newsletter', loading: false });
    }
  },

  updateNewsletter: async (id, data) => {
    set({ loading: true, error: null });
    try {
      // Simulated API call
      await new Promise(resolve => setTimeout(resolve, 1000));
      
      set((state) => ({
        newsletters: state.newsletters.map((newsletter) =>
          newsletter.id === id
            ? { ...newsletter, ...data, updatedAt: new Date().toISOString() }
            : newsletter
        ),
        loading: false
      }));
    } catch (error) {
      set({ error: 'Failed to update newsletter', loading: false });
    }
  },

  deleteNewsletter: async (id) => {
    set({ loading: true, error: null });
    try {
      // Simulated API call
      await new Promise(resolve => setTimeout(resolve, 1000));
      
      set((state) => ({
        newsletters: state.newsletters.filter((newsletter) => newsletter.id !== id),
        loading: false
      }));
    } catch (error) {
      set({ error: 'Failed to delete newsletter', loading: false });
    }
  },

  scheduleNewsletter: async (id, date) => {
    set({ loading: true, error: null });
    try {
      // Simulated API call
      await new Promise(resolve => setTimeout(resolve, 1000));
      
      set((state) => ({
        newsletters: state.newsletters.map((newsletter) =>
          newsletter.id === id
            ? {
                ...newsletter,
                status: 'scheduled',
                scheduledFor: date,
                updatedAt: new Date().toISOString()
              }
            : newsletter
        ),
        loading: false
      }));
    } catch (error) {
      set({ error: 'Failed to schedule newsletter', loading: false });
    }
  },

  sendNewsletter: async (id) => {
    set({ loading: true, error: null });
    try {
      // Simulated API call
      await new Promise(resolve => setTimeout(resolve, 1000));
      
      set((state) => ({
        newsletters: state.newsletters.map((newsletter) =>
          newsletter.id === id
            ? {
                ...newsletter,
                status: 'sent',
                sentAt: new Date().toISOString(),
                updatedAt: new Date().toISOString()
              }
            : newsletter
        ),
        loading: false
      }));
    } catch (error) {
      set({ error: 'Failed to send newsletter', loading: false });
    }
  }
}));