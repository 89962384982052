import React from 'react';
import { Shield, Smartphone, Bell } from 'lucide-react';
import { useSettingsStore } from '../../store/settingsStore';

export function SecuritySection() {
  const { security, updateSecurity } = useSettingsStore();

  const handleRemoveDevice = (deviceId: string) => {
    const updatedDevices = security.trustedDevices.filter(
      (device) => device.id !== deviceId
    );
    updateSecurity({ trustedDevices: updatedDevices });
  };

  return (
    <div className="bg-white dark:bg-gray-800 rounded-lg shadow-md p-6">
      <h2 className="text-xl font-semibold mb-6">Security Settings</h2>

      <div className="space-y-6">
        <div className="flex items-center justify-between">
          <div className="flex items-center space-x-3">
            <Shield className="h-5 w-5 text-blue-500" />
            <div>
              <p className="font-medium">Two-Factor Authentication</p>
              <p className="text-sm text-gray-500 dark:text-gray-400">
                Add an extra layer of security to your account
              </p>
            </div>
          </div>
          <label className="relative inline-flex items-center cursor-pointer">
            <input
              type="checkbox"
              checked={security.twoFactorEnabled}
              onChange={(e) => updateSecurity({ twoFactorEnabled: e.target.checked })}
              className="sr-only peer"
            />
            <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
          </label>
        </div>

        <div className="flex items-center justify-between">
          <div className="flex items-center space-x-3">
            <Bell className="h-5 w-5 text-purple-500" />
            <div>
              <p className="font-medium">Login Notifications</p>
              <p className="text-sm text-gray-500 dark:text-gray-400">
                Get notified of new login attempts
              </p>
            </div>
          </div>
          <label className="relative inline-flex items-center cursor-pointer">
            <input
              type="checkbox"
              checked={security.loginNotifications}
              onChange={(e) => updateSecurity({ loginNotifications: e.target.checked })}
              className="sr-only peer"
            />
            <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
          </label>
        </div>

        <div className="border-t border-gray-200 dark:border-gray-700 pt-6">
          <div className="flex items-center space-x-3 mb-4">
            <Smartphone className="h-5 w-5 text-green-500" />
            <div>
              <p className="font-medium">Trusted Devices</p>
              <p className="text-sm text-gray-500 dark:text-gray-400">
                Manage devices that have access to your account
              </p>
            </div>
          </div>

          <div className="space-y-4">
            {security.trustedDevices.map((device) => (
              <div
                key={device.id}
                className="flex items-center justify-between bg-gray-50 dark:bg-gray-700 p-4 rounded-lg"
              >
                <div>
                  <p className="font-medium">{device.name}</p>
                  <p className="text-sm text-gray-500 dark:text-gray-400">
                    Last used: {new Date(device.lastUsed).toLocaleDateString()}
                  </p>
                </div>
                <button
                  onClick={() => handleRemoveDevice(device.id)}
                  className="text-red-500 hover:text-red-600 text-sm"
                >
                  Remove
                </button>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}