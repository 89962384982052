import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { useTheme } from './hooks/useTheme';
import { Header } from './components/layout/Header';
import { Sidebar } from './components/layout/Sidebar';
import { PrivateRoute } from './components/auth/PrivateRoute';
import { Login } from './pages/Login';
import { Analytics } from './pages/Analytics';
import { Patients } from './pages/Patients';
import { ReferringDoctors } from './pages/ReferringDoctors';
import { Appointments } from './pages/Appointments';
import { Messages } from './pages/Messages';
import { Newsletters } from './pages/Newsletters';
import { Settings } from './pages/Settings';

function Layout({ children }: { children: React.ReactNode }) {
  return (
    <div className="flex h-screen bg-gray-100 dark:bg-gray-900">
      <Sidebar />
      <div className="flex-1 flex flex-col overflow-hidden">
        <Header />
        <main className="flex-1 overflow-y-auto pt-16 px-8 py-6">
          <div className="max-w-7xl mx-auto">
            {children}
          </div>
        </main>
      </div>
    </div>
  );
}

function App() {
  // Initialize theme
  useTheme();

  return (
    <Router>
      <Routes>
        <Route path="/login" element={<Login />} />
        
        <Route path="/" element={
          <PrivateRoute>
            <Layout>
              <Analytics />
            </Layout>
          </PrivateRoute>
        } />
        
        <Route path="/analytics" element={
          <PrivateRoute>
            <Layout>
              <Analytics />
            </Layout>
          </PrivateRoute>
        } />
        
        <Route path="/patients" element={
          <PrivateRoute>
            <Layout>
              <Patients />
            </Layout>
          </PrivateRoute>
        } />
        
        <Route path="/referring-doctors" element={
          <PrivateRoute>
            <Layout>
              <ReferringDoctors />
            </Layout>
          </PrivateRoute>
        } />
        
        <Route path="/appointments" element={
          <PrivateRoute>
            <Layout>
              <Appointments />
            </Layout>
          </PrivateRoute>
        } />
        
        <Route path="/messages" element={
          <PrivateRoute>
            <Layout>
              <Messages />
            </Layout>
          </PrivateRoute>
        } />
        
        <Route path="/newsletters" element={
          <PrivateRoute>
            <Layout>
              <Newsletters />
            </Layout>
          </PrivateRoute>
        } />
        
        <Route path="/settings" element={
          <PrivateRoute>
            <Layout>
              <Settings />
            </Layout>
          </PrivateRoute>
        } />

        <Route path="*" element={<Navigate to="/analytics" replace />} />
      </Routes>
    </Router>
  );
}

export default App;