import React from 'react';
import { Calendar, Users, Edit2, Trash2, Send, Archive, Eye } from 'lucide-react';
import { Newsletter } from '../../types/newsletter';
import { useNewsletterStore } from '../../store/newsletterStore';
import { formatDistanceToNow } from 'date-fns';

interface NewsletterListProps {
  newsletters: Newsletter[];
}

export function NewsletterList({ newsletters }: NewsletterListProps) {
  const { setSelectedNewsletter, deleteNewsletter, sendNewsletter } = useNewsletterStore();

  const getStatusColor = (status: Newsletter['status']) => {
    switch (status) {
      case 'draft':
        return 'bg-gray-100 text-gray-800 dark:bg-gray-700 dark:text-gray-200';
      case 'scheduled':
        return 'bg-blue-100 text-blue-800 dark:bg-blue-900 dark:text-blue-200';
      case 'sent':
        return 'bg-green-100 text-green-800 dark:bg-green-900 dark:text-green-200';
      case 'archived':
        return 'bg-purple-100 text-purple-800 dark:bg-purple-900 dark:text-purple-200';
    }
  };

  return (
    <div className="grid gap-6">
      {newsletters.map((newsletter) => (
        <div
          key={newsletter.id}
          className="bg-white dark:bg-gray-800 rounded-lg shadow-md p-6"
        >
          <div className="flex items-start justify-between">
            <div className="space-y-2">
              <h3 className="text-lg font-semibold">{newsletter.title}</h3>
              <div className="flex items-center space-x-4 text-sm text-gray-500 dark:text-gray-400">
                <span className="flex items-center">
                  <Calendar className="h-4 w-4 mr-1" />
                  {formatDistanceToNow(new Date(newsletter.createdAt), { addSuffix: true })}
                </span>
                <span className="flex items-center">
                  <Users className="h-4 w-4 mr-1" />
                  {newsletter.recipients.count} recipients
                </span>
                <span className={`px-2 py-1 rounded-full text-xs ${getStatusColor(newsletter.status)}`}>
                  {newsletter.status}
                </span>
              </div>
            </div>

            <div className="flex items-center space-x-2">
              <button
                onClick={() => setSelectedNewsletter(newsletter)}
                className="p-2 text-gray-400 hover:text-blue-500 rounded-lg"
                title="Edit"
              >
                <Edit2 className="h-5 w-5" />
              </button>
              {newsletter.status === 'sent' && (
                <button
                  className="p-2 text-gray-400 hover:text-purple-500 rounded-lg"
                  title="View Stats"
                >
                  <Eye className="h-5 w-5" />
                </button>
              )}
              {newsletter.status === 'draft' && (
                <button
                  onClick={() => sendNewsletter(newsletter.id)}
                  className="p-2 text-gray-400 hover:text-green-500 rounded-lg"
                  title="Send"
                >
                  <Send className="h-5 w-5" />
                </button>
              )}
              <button
                onClick={() => deleteNewsletter(newsletter.id)}
                className="p-2 text-gray-400 hover:text-red-500 rounded-lg"
                title="Delete"
              >
                <Trash2 className="h-5 w-5" />
              </button>
            </div>
          </div>

          {newsletter.stats && (
            <div className="mt-4 grid grid-cols-3 gap-4">
              <div className="bg-gray-50 dark:bg-gray-700 p-3 rounded-lg">
                <p className="text-sm text-gray-500 dark:text-gray-400">Opened</p>
                <p className="text-lg font-semibold">{newsletter.stats.opened}</p>
              </div>
              <div className="bg-gray-50 dark:bg-gray-700 p-3 rounded-lg">
                <p className="text-sm text-gray-500 dark:text-gray-400">Clicked</p>
                <p className="text-lg font-semibold">{newsletter.stats.clicked}</p>
              </div>
              <div className="bg-gray-50 dark:bg-gray-700 p-3 rounded-lg">
                <p className="text-sm text-gray-500 dark:text-gray-400">Bounced</p>
                <p className="text-lg font-semibold">{newsletter.stats.bounced}</p>
              </div>
            </div>
          )}

          {newsletter.status === 'scheduled' && newsletter.scheduledFor && (
            <div className="mt-4 text-sm text-blue-500">
              Scheduled for: {new Date(newsletter.scheduledFor).toLocaleString()}
            </div>
          )}
        </div>
      ))}
    </div>
  );
}