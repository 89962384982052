import React from 'react';
import { MessagingProvider } from '../../services/messaging/types';

interface ProviderSelectorProps {
  providers: MessagingProvider[];
  selectedProvider: string;
  onSelect: (providerId: string) => void;
}

export function ProviderSelector({ providers, selectedProvider, onSelect }: ProviderSelectorProps) {
  return (
    <div className="flex space-x-2 mb-4 overflow-x-auto pb-2">
      {providers.map((provider) => (
        <button
          key={provider.id}
          onClick={() => onSelect(provider.id)}
          disabled={!provider.isEnabled}
          className={`
            flex items-center px-4 py-2 rounded-lg transition-colors
            ${provider.id === selectedProvider
              ? 'bg-blue-500 text-white'
              : provider.isEnabled
                ? 'bg-white dark:bg-gray-800 hover:bg-gray-50 dark:hover:bg-gray-700'
                : 'bg-gray-100 dark:bg-gray-800 text-gray-400 dark:text-gray-600 cursor-not-allowed'
            }
          `}
        >
          <span className="mr-2">{provider.icon}</span>
          <span>{provider.name}</span>
          {!provider.isEnabled && (
            <span className="ml-2 text-xs">(Coming Soon)</span>
          )}
        </button>
      ))}
    </div>
  );
}