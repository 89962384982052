import React from 'react';
import { Users, UserPlus, Calendar } from 'lucide-react';
import { StatCard } from '../components/analytics/StatCard';
import { LineChart } from '../components/analytics/LineChart';
import { useAnalyticsStore } from '../store/analyticsStore';
import { PageContainer } from '../components/layout/PageContainer';

export function Analytics() {
  const { stats, patientTrend, referralTrend, loading, error, fetchAnalytics } = useAnalyticsStore();

  return (
    <PageContainer
      title="Analytics Dashboard"
      isLoading={loading}
      error={error}
      onRetry={fetchAnalytics}
      actions={
        <select className="bg-gray-700 text-white px-4 py-2 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500">
          <option value="7d">Last 7 days</option>
          <option value="30d">Last 30 days</option>
          <option value="90d">Last 90 days</option>
        </select>
      }
    >
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        <StatCard
          title="Total Patients"
          value={stats.totalPatients}
          icon={Users}
          color="text-blue-500"
          change={{ value: 12, type: 'increase' }}
        />
        <StatCard
          title="Active Referrals"
          value={stats.activeReferrals}
          icon={UserPlus}
          color="text-purple-500"
          change={{ value: 5, type: 'increase' }}
        />
        <StatCard
          title="Today's Appointments"
          value={stats.todayAppointments}
          icon={Calendar}
          color="text-pink-500"
          change={{ value: 2, type: 'decrease' }}
        />
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        <LineChart
          data={patientTrend}
          title="Patient Growth"
          color="#3B82F6"
        />
        <LineChart
          data={referralTrend}
          title="Referral Trend"
          color="#8B5CF6"
        />
      </div>
    </PageContainer>
  );
}