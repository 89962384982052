import { create } from 'zustand';
import { addDays, startOfToday } from 'date-fns';
import { Appointment, AppointmentFilters } from '../types/appointment';

interface AppointmentsState {
  appointments: Appointment[];
  filters: AppointmentFilters;
  loading: boolean;
  error: string | null;
  setFilters: (filters: Partial<AppointmentFilters>) => void;
  fetchAppointments: () => Promise<void>;
}

const getStatusColor = (status: Appointment['status']) => {
  switch (status) {
    case 'scheduled': return '#3B82F6';
    case 'confirmed': return '#10B981';
    case 'completed': return '#6366F1';
    case 'cancelled': return '#EF4444';
    case 'no-show': return '#F59E0B';
    default: return '#3B82F6';
  }
};

export const useAppointmentsStore = create<AppointmentsState>((set) => ({
  appointments: [
    {
      id: '1',
      title: 'Dental Checkup - John Smith',
      start: new Date().toISOString(),
      end: addDays(new Date(), 1).toISOString(),
      patient: {
        id: '1',
        name: 'John Smith',
        email: 'john.smith@email.com',
        phone: '(555) 123-4567',
        dateOfBirth: '1985-06-15',
        lastVisit: '2024-02-15',
        nextAppointment: '2024-03-20',
        status: 'active',
        insuranceProvider: 'Blue Cross',
        notes: 'Regular checkups, no major issues.'
      },
      type: 'checkup',
      status: 'scheduled',
      notes: 'Regular checkup appointment',
      color: getStatusColor('scheduled')
    }
  ],
  filters: {
    status: 'all',
    type: 'all',
    date: startOfToday(),
    view: 'week'
  },
  loading: false,
  error: null,
  setFilters: (newFilters) =>
    set((state) => ({
      filters: { ...state.filters, ...newFilters }
    })),
  fetchAppointments: async () => {
    set({ loading: true });
    try {
      // Simulated API call
      await new Promise(resolve => setTimeout(resolve, 1000));
      set({ loading: false });
    } catch (error) {
      set({ error: 'Failed to fetch appointments', loading: false });
    }
  }
}));