import React from 'react';
import { useSettingsStore } from '../store/settingsStore';
import { ProfileSection } from '../components/settings/ProfileSection';
import { NotificationSection } from '../components/settings/NotificationSection';
import { SecuritySection } from '../components/settings/SecuritySection';
import { PreferencesSection } from '../components/settings/PreferencesSection';

export function Settings() {
  const { loading, error } = useSettingsStore();

  if (loading) {
    return (
      <div className="flex items-center justify-center h-full">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  return (
    <div className="space-y-8 pb-8">
      <div className="flex items-center justify-between">
        <h1 className="text-2xl font-semibold">Settings</h1>
      </div>

      {error && (
        <div className="bg-red-50 dark:bg-red-900/50 text-red-600 dark:text-red-200 p-4 rounded-lg">
          {error}
        </div>
      )}

      <div className="space-y-8">
        <ProfileSection />
        <NotificationSection />
        <SecuritySection />
        <PreferencesSection />
      </div>
    </div>
  );
}