import React from 'react';
import { Mail, Phone, MapPin, Calendar, BarChart } from 'lucide-react';
import { ReferringDoctor } from '../../types/referringDoctor';

interface ReferringDoctorListProps {
  doctors: ReferringDoctor[];
}

export function ReferringDoctorList({ doctors }: ReferringDoctorListProps) {
  return (
    <div className="grid gap-4">
      {doctors.map((doctor) => (
        <div
          key={doctor.id}
          className="bg-white dark:bg-gray-800 rounded-lg shadow-md p-6"
        >
          <div className="flex justify-between items-start">
            <div className="space-y-4">
              <div>
                <h3 className="text-lg font-semibold">{doctor.name}</h3>
                <p className="text-sm text-purple-500 dark:text-purple-400">
                  {doctor.specialty}
                </p>
                <p className="text-sm text-gray-600 dark:text-gray-400">
                  {doctor.practice}
                </p>
              </div>

              <div className="space-y-2">
                <div className="flex items-center text-sm text-gray-600 dark:text-gray-400">
                  <Mail className="h-4 w-4 mr-2" />
                  {doctor.email}
                </div>
                <div className="flex items-center text-sm text-gray-600 dark:text-gray-400">
                  <Phone className="h-4 w-4 mr-2" />
                  {doctor.phone}
                </div>
                <div className="flex items-center text-sm text-gray-600 dark:text-gray-400">
                  <MapPin className="h-4 w-4 mr-2" />
                  {doctor.address}
                </div>
              </div>
            </div>

            <div className="flex flex-col items-end space-y-2">
              <span className={`px-3 py-1 rounded-full text-sm ${
                doctor.status === 'active'
                  ? 'bg-green-100 text-green-800 dark:bg-green-900 dark:text-green-200'
                  : 'bg-gray-100 text-gray-800 dark:bg-gray-700 dark:text-gray-200'
              }`}>
                {doctor.status}
              </span>
              
              <div className="flex items-center text-sm text-gray-600 dark:text-gray-400">
                <BarChart className="h-4 w-4 mr-1" />
                {doctor.referralCount} referrals
              </div>

              {doctor.lastReferral && (
                <div className="flex items-center text-sm text-gray-600 dark:text-gray-400">
                  <Calendar className="h-4 w-4 mr-1" />
                  Last: {new Date(doctor.lastReferral).toLocaleDateString()}
                </div>
              )}
            </div>
          </div>
          
          {doctor.notes && (
            <div className="mt-4 pt-4 border-t border-gray-200 dark:border-gray-700">
              <p className="text-sm text-gray-600 dark:text-gray-400">{doctor.notes}</p>
            </div>
          )}
        </div>
      ))}
    </div>
  );
}