import React from 'react';
import { Moon, Globe2, Clock } from 'lucide-react';
import { useSettingsStore } from '../../store/settingsStore';
import type { Theme, Language, TimeFormat } from '../../types/settings';

export function PreferencesSection() {
  const { preferences, updatePreferences } = useSettingsStore();

  const handleChange = (key: keyof typeof preferences, value: Theme | Language | TimeFormat | string) => {
    updatePreferences({ [key]: value });
  };

  return (
    <div className="bg-white dark:bg-gray-800 rounded-lg shadow-md p-6">
      <h2 className="text-xl font-semibold mb-6">Preferences</h2>

      <div className="space-y-6">
        <div className="flex items-center justify-between">
          <div className="flex items-center space-x-3">
            <Moon className="h-5 w-5 text-blue-500" />
            <div>
              <p className="font-medium">Theme</p>
              <p className="text-sm text-gray-500 dark:text-gray-400">
                Choose your preferred theme
              </p>
            </div>
          </div>
          <select
            value={preferences.theme}
            onChange={(e) => handleChange('theme', e.target.value as Theme)}
            className="bg-white dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-lg px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
            <option value="light">Light</option>
            <option value="dark">Dark</option>
            <option value="system">System</option>
          </select>
        </div>

        <div className="flex items-center justify-between">
          <div className="flex items-center space-x-3">
            <Globe2 className="h-5 w-5 text-purple-500" />
            <div>
              <p className="font-medium">Language</p>
              <p className="text-sm text-gray-500 dark:text-gray-400">
                Select your preferred language
              </p>
            </div>
          </div>
          <select
            value={preferences.language}
            onChange={(e) => handleChange('language', e.target.value as Language)}
            className="bg-white dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-lg px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
            <option value="en">English</option>
            <option value="es">Español</option>
            <option value="fr">Français</option>
          </select>
        </div>

        <div className="flex items-center justify-between">
          <div className="flex items-center space-x-3">
            <Clock className="h-5 w-5 text-green-500" />
            <div>
              <p className="font-medium">Time Format</p>
              <p className="text-sm text-gray-500 dark:text-gray-400">
                Choose how times are displayed
              </p>
            </div>
          </div>
          <select
            value={preferences.timeFormat}
            onChange={(e) => handleChange('timeFormat', e.target.value as TimeFormat)}
            className="bg-white dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-lg px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
            <option value="12h">12-hour</option>
            <option value="24h">24-hour</option>
          </select>
        </div>

        <div className="flex items-center justify-between">
          <div className="flex items-center space-x-3">
            <Globe2 className="h-5 w-5 text-yellow-500" />
            <div>
              <p className="font-medium">Timezone</p>
              <p className="text-sm text-gray-500 dark:text-gray-400">
                Set your local timezone
              </p>
            </div>
          </div>
          <select
            value={preferences.timezone}
            onChange={(e) => handleChange('timezone', e.target.value)}
            className="bg-white dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-lg px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
            <option value="America/Chicago">Central Time</option>
            <option value="America/New_York">Eastern Time</option>
            <option value="America/Los_Angeles">Pacific Time</option>
            <option value="America/Denver">Mountain Time</option>
          </select>
        </div>
      </div>
    </div>
  );
}