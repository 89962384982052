import React, { useEffect } from 'react';
import { UserPlus } from 'lucide-react';
import { ReferringDoctorFilters } from '../components/referring-doctors/ReferringDoctorFilters';
import { ReferringDoctorList } from '../components/referring-doctors/ReferringDoctorList';
import { useReferringDoctorsStore } from '../store/referringDoctorsStore';

export function ReferringDoctors() {
  const { doctors, loading, error, fetchDoctors, filters } = useReferringDoctorsStore();

  useEffect(() => {
    fetchDoctors();
  }, [fetchDoctors]);

  const filteredDoctors = doctors.filter((doctor) => {
    const matchesSearch = doctor.name.toLowerCase().includes(filters.search.toLowerCase()) ||
      doctor.email.toLowerCase().includes(filters.search.toLowerCase()) ||
      doctor.practice.toLowerCase().includes(filters.search.toLowerCase());
    const matchesStatus = filters.status === 'all' || doctor.status === filters.status;
    const matchesSpecialty = filters.specialty === 'all' || doctor.specialty === filters.specialty;

    return matchesSearch && matchesStatus && matchesSpecialty;
  });

  if (loading) {
    return (
      <div className="flex items-center justify-center h-full">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-center py-12">
        <p className="text-red-500">{error}</p>
        <button
          onClick={() => fetchDoctors()}
          className="mt-4 px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors"
        >
          Retry
        </button>
      </div>
    );
  }

  return (
    <div className="space-y-6 animate-fade-in">
      <div className="flex items-center justify-between">
        <h1 className="text-2xl font-semibold">Referring Doctors</h1>
        <button className="flex items-center px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors">
          <UserPlus className="h-5 w-5 mr-2" />
          Add New Doctor
        </button>
      </div>

      <ReferringDoctorFilters />
      <ReferringDoctorList doctors={filteredDoctors} />
    </div>
  );
}