import { create } from 'zustand';
import { Message, MessageFilters } from '../types/message';
import { TelnyxService } from '../services/telnyxService';

const telnyxService = new TelnyxService();

interface MessagesState {
  messages: Message[];
  filters: MessageFilters;
  selectedMessage: Message | null;
  loading: boolean;
  error: string | null;
  setFilters: (filters: Partial<MessageFilters>) => void;
  setSelectedMessage: (message: Message | null) => void;
  fetchMessages: () => Promise<void>;
  markAsRead: (messageId: string) => void;
  archiveMessage: (messageId: string) => void;
  deleteMessage: (messageId: string) => void;
}

export const useMessagesStore = create<MessagesState>((set) => ({
  messages: [
    {
      id: '1',
      type: 'email',
      subject: 'Patient Referral - John Smith',
      content: 'I am referring a patient with complex dental needs...',
      sender: {
        id: 'dr1',
        name: 'Dr. Michael Chen',
        email: 'michael.chen@dentalcare.com',
        avatar: 'https://ui-avatars.com/api/?name=Michael+Chen'
      },
      recipient: {
        id: 'staff1',
        name: 'Dr. Sarah Johnson',
        email: 'sarah.johnson@dentalreferrals.net'
      },
      timestamp: new Date().toISOString(),
      status: 'unread',
      priority: 'high'
    },
    {
      id: '2',
      type: 'voicemail',
      subject: 'Appointment Rescheduling Request',
      content: 'Voice message from Emma Wilson',
      sender: {
        id: 'pat1',
        name: 'Emma Wilson',
        phone: '(555) 987-6543'
      },
      recipient: {
        id: 'staff1',
        name: 'Dr. Sarah Johnson',
        email: 'sarah.johnson@dentalreferrals.net'
      },
      timestamp: new Date(Date.now() - 3600000).toISOString(),
      status: 'unread',
      priority: 'normal',
      duration: 45,
      transcription: 'Hi, this is Emma Wilson. I need to reschedule my appointment...'
    }
  ],
  filters: {
    type: 'all',
    status: 'all',
    priority: 'all',
    search: ''
  },
  selectedMessage: null,
  loading: false,
  error: null,
  setFilters: (newFilters) =>
    set((state) => ({
      filters: { ...state.filters, ...newFilters }
    })),
  setSelectedMessage: (message) =>
    set({ selectedMessage: message }),
  fetchMessages: async () => {
    set({ loading: true });
    try {
      const messages = await telnyxService.fetchMessages();
      set({ messages, loading: false });
    } catch (error) {
      set({ error: 'Failed to fetch messages', loading: false });
    }
  },
  
  sendMessage: async (to: string, content: string) => {
    set({ loading: true });
    try {
      await telnyxService.sendMessage(to, content);
      // Refresh messages after sending
      const messages = await telnyxService.fetchMessages();
      set({ messages, loading: false });
    } catch (error) {
      set({ error: 'Failed to send message', loading: false });
    }
  },
  markAsRead: (messageId) =>
    set((state) => ({
      messages: state.messages.map((msg) =>
        msg.id === messageId ? { ...msg, status: 'read' } : msg
      )
    })),
  archiveMessage: (messageId) =>
    set((state) => ({
      messages: state.messages.map((msg) =>
        msg.id === messageId ? { ...msg, status: 'archived' } : msg
      )
    })),
  deleteMessage: (messageId) =>
    set((state) => ({
      messages: state.messages.map((msg) =>
        msg.id === messageId ? { ...msg, status: 'deleted' } : msg
      )
    }))
}));