import React from 'react';
import { Search, Filter, Calendar } from 'lucide-react';
import { useNewsletterStore } from '../../store/newsletterStore';
import { NewsletterStatus, NewsletterTemplate } from '../../types/newsletter';

export function NewsletterFilters() {
  const { filters, setFilters } = useNewsletterStore();

  const statusOptions: { value: NewsletterStatus | 'all'; label: string }[] = [
    { value: 'all', label: 'All Status' },
    { value: 'draft', label: 'Drafts' },
    { value: 'scheduled', label: 'Scheduled' },
    { value: 'sent', label: 'Sent' },
    { value: 'archived', label: 'Archived' }
  ];

  const templateOptions: { value: NewsletterTemplate | 'all'; label: string }[] = [
    { value: 'all', label: 'All Templates' },
    { value: 'welcome', label: 'Welcome' },
    { value: 'monthly-update', label: 'Monthly Update' },
    { value: 'appointment-reminder', label: 'Appointment Reminder' },
    { value: 'custom', label: 'Custom' }
  ];

  return (
    <div className="grid grid-cols-1 md:grid-cols-4 gap-4 mb-6">
      <div className="relative">
        <Search className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
        <input
          type="text"
          placeholder="Search newsletters..."
          value={filters.search}
          onChange={(e) => setFilters({ search: e.target.value })}
          className="w-full pl-10 px-4 py-2 bg-white dark:bg-gray-800 border border-gray-200 dark:border-gray-700 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
      </div>

      <select
        value={filters.status}
        onChange={(e) => setFilters({ status: e.target.value as NewsletterStatus | 'all' })}
        className="px-4 py-2 bg-white dark:bg-gray-800 border border-gray-200 dark:border-gray-700 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
      >
        {statusOptions.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>

      <select
        value={filters.template}
        onChange={(e) => setFilters({ template: e.target.value as NewsletterTemplate | 'all' })}
        className="px-4 py-2 bg-white dark:bg-gray-800 border border-gray-200 dark:border-gray-700 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
      >
        {templateOptions.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>

      <div className="flex space-x-2">
        <div className="relative flex-1">
          <Calendar className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
          <input
            type="date"
            value={filters.dateRange.start}
            onChange={(e) => setFilters({
              dateRange: { ...filters.dateRange, start: e.target.value }
            })}
            className="w-full pl-10 px-4 py-2 bg-white dark:bg-gray-800 border border-gray-200 dark:border-gray-700 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>
        <div className="relative flex-1">
          <Calendar className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
          <input
            type="date"
            value={filters.dateRange.end}
            onChange={(e) => setFilters({
              dateRange: { ...filters.dateRange, end: e.target.value }
            })}
            className="w-full pl-10 px-4 py-2 bg-white dark:bg-gray-800 border border-gray-200 dark:border-gray-700 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>
      </div>
    </div>
  );
}