import React from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import { useAppointmentsStore } from '../../store/appointmentsStore';

export function AppointmentCalendar() {
  const { appointments, filters } = useAppointmentsStore();

  return (
    <div className="bg-white dark:bg-gray-800 rounded-lg shadow-md p-6">
      <FullCalendar
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
        initialView={filters.view}
        headerToolbar={{
          left: 'prev,next today',
          center: 'title',
          right: 'dayGridMonth,timeGridWeek,timeGridDay'
        }}
        events={appointments}
        editable={true}
        selectable={true}
        selectMirror={true}
        dayMaxEvents={true}
        weekends={true}
        initialDate={filters.date}
        height="auto"
        aspectRatio={1.8}
        themeSystem="standard"
        slotMinTime="08:00:00"
        slotMaxTime="18:00:00"
        allDaySlot={false}
        slotDuration="00:30:00"
        eventTimeFormat={{
          hour: 'numeric',
          minute: '2-digit',
          meridiem: 'short'
        }}
        eventClick={(info) => {
          console.log('Event clicked:', info.event);
        }}
        dateClick={(info) => {
          console.log('Date clicked:', info.date);
        }}
        select={(info) => {
          console.log('Selection:', info.start, info.end);
        }}
      />
    </div>
  );
}