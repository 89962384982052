import React from 'react';
import { Filter, Mail, MessageSquare, Voicemail } from 'lucide-react';
import { useMessagesStore } from '../../store/messagesStore';
import { MessageType, MessageStatus, MessagePriority } from '../../types/message';

export function MessageFilters() {
  const { filters, setFilters } = useMessagesStore();

  const typeOptions: { value: MessageType | 'all'; label: string; icon: React.ReactNode }[] = [
    { value: 'all', label: 'All Messages', icon: <MessageSquare className="h-4 w-4" /> },
    { value: 'message', label: 'Messages', icon: <MessageSquare className="h-4 w-4" /> },
    { value: 'email', label: 'Emails', icon: <Mail className="h-4 w-4" /> },
    { value: 'voicemail', label: 'Voicemails', icon: <Voicemail className="h-4 w-4" /> }
  ];

  const statusOptions: { value: MessageStatus | 'all'; label: string }[] = [
    { value: 'all', label: 'All Status' },
    { value: 'unread', label: 'Unread' },
    { value: 'read', label: 'Read' },
    { value: 'archived', label: 'Archived' }
  ];

  const priorityOptions: { value: MessagePriority | 'all'; label: string }[] = [
    { value: 'all', label: 'All Priority' },
    { value: 'high', label: 'High' },
    { value: 'normal', label: 'Normal' },
    { value: 'low', label: 'Low' }
  ];

  return (
    <div className="grid grid-cols-1 md:grid-cols-4 gap-4 mb-6">
      <div className="relative">
        <input
          type="text"
          placeholder="Search messages..."
          value={filters.search}
          onChange={(e) => setFilters({ search: e.target.value })}
          className="w-full bg-white dark:bg-gray-800 px-4 py-2 rounded-lg border border-gray-200 dark:border-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
      </div>

      <select
        value={filters.type}
        onChange={(e) => setFilters({ type: e.target.value as MessageType | 'all' })}
        className="bg-white dark:bg-gray-800 px-4 py-2 rounded-lg border border-gray-200 dark:border-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
      >
        {typeOptions.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>

      <select
        value={filters.status}
        onChange={(e) => setFilters({ status: e.target.value as MessageStatus | 'all' })}
        className="bg-white dark:bg-gray-800 px-4 py-2 rounded-lg border border-gray-200 dark:border-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
      >
        {statusOptions.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>

      <select
        value={filters.priority}
        onChange={(e) => setFilters({ priority: e.target.value as MessagePriority | 'all' })}
        className="bg-white dark:bg-gray-800 px-4 py-2 rounded-lg border border-gray-200 dark:border-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
      >
        {priorityOptions.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );
}