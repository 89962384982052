import React, { useEffect } from 'react';
import { Plus, FileText, Users, Calendar } from 'lucide-react';
import { NewsletterList } from '../components/newsletters/NewsletterList';
import { NewsletterFilters } from '../components/newsletters/NewsletterFilters';
import { NewsletterEditor } from '../components/newsletters/NewsletterEditor';
import { useNewsletterStore } from '../store/newsletterStore';

export function Newsletters() {
  const {
    newsletters,
    filters,
    loading,
    error,
    fetchNewsletters,
    selectedNewsletter,
    setSelectedNewsletter
  } = useNewsletterStore();
  const [isCreating, setIsCreating] = React.useState(false);

  useEffect(() => {
    fetchNewsletters();
  }, [fetchNewsletters]);

  const filteredNewsletters = newsletters.filter((newsletter) => {
    const matchesSearch = newsletter.title.toLowerCase().includes(filters.search.toLowerCase()) ||
      newsletter.subject.toLowerCase().includes(filters.search.toLowerCase());
    const matchesStatus = filters.status === 'all' || newsletter.status === filters.status;
    const matchesTemplate = filters.template === 'all' || newsletter.template === filters.template;
    const matchesDateRange = !filters.dateRange.start || !filters.dateRange.end ||
      (new Date(newsletter.createdAt) >= new Date(filters.dateRange.start) &&
       new Date(newsletter.createdAt) <= new Date(filters.dateRange.end));

    return matchesSearch && matchesStatus && matchesTemplate && matchesDateRange;
  });

  if (loading) {
    return (
      <div className="flex items-center justify-center h-full">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-center py-12">
        <p className="text-red-500">{error}</p>
        <button
          onClick={() => fetchNewsletters()}
          className="mt-4 px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600"
        >
          Retry
        </button>
      </div>
    );
  }

  return (
    <div className="space-y-6 animate-fade-in">
      <div className="flex items-center justify-between">
        <h1 className="text-2xl font-semibold">Newsletters</h1>
        <div className="flex space-x-4">
          <button 
            className="flex items-center px-4 py-2 bg-gray-100 text-gray-700 dark:bg-gray-700 dark:text-gray-200 rounded-lg hover:bg-gray-200 dark:hover:bg-gray-600"
          >
            <FileText className="h-5 w-5 mr-2" />
            Templates
          </button>
          <button 
            className="flex items-center px-4 py-2 bg-gray-100 text-gray-700 dark:bg-gray-700 dark:text-gray-200 rounded-lg hover:bg-gray-200 dark:hover:bg-gray-600"
          >
            <Users className="h-5 w-5 mr-2" />
            Recipients
          </button>
          <button
            onClick={() => setIsCreating(true)}
            className="flex items-center px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600"
          >
            <Plus className="h-5 w-5 mr-2" />
            Create Newsletter
          </button>
        </div>
      </div>

      <NewsletterFilters />

      {isCreating || selectedNewsletter ? (
        <NewsletterEditor
          newsletter={selectedNewsletter}
          onClose={() => {
            setIsCreating(false);
            setSelectedNewsletter(null);
          }}
        />
      ) : (
        <NewsletterList newsletters={filteredNewsletters} />
      )}
    </div>
  );
}