import { create } from 'zustand';
import { ReferringDoctor, ReferringDoctorFilters } from '../types/referringDoctor';

interface ReferringDoctorsState {
  doctors: ReferringDoctor[];
  filters: ReferringDoctorFilters;
  loading: boolean;
  error: string | null;
  setFilters: (filters: Partial<ReferringDoctorFilters>) => void;
  fetchDoctors: () => Promise<void>;
}

export const useReferringDoctorsStore = create<ReferringDoctorsState>((set) => ({
  doctors: [
    {
      id: '1',
      name: 'Dr. Michael Chen',
      specialty: 'Orthodontics',
      email: 'michael.chen@dentalcare.com',
      phone: '(555) 234-5678',
      practice: 'Chen Orthodontics',
      address: '123 Medical Plaza, Suite 456, San Francisco, CA 94110',
      referralCount: 45,
      status: 'active',
      lastReferral: '2024-03-01',
      notes: 'Frequent referrer, specializes in complex cases'
    },
    {
      id: '2',
      name: 'Dr. Emily Rodriguez',
      specialty: 'Periodontics',
      email: 'e.rodriguez@perio.net',
      phone: '(555) 876-5432',
      practice: 'Bay Area Periodontics',
      address: '789 Healthcare Drive, Oakland, CA 94612',
      referralCount: 28,
      status: 'active',
      lastReferral: '2024-02-28',
      notes: 'Excellent communication, prefers email updates'
    }
  ],
  filters: {
    status: 'all',
    specialty: 'all',
    search: ''
  },
  loading: false,
  error: null,
  setFilters: (newFilters) =>
    set((state) => ({
      filters: { ...state.filters, ...newFilters }
    })),
  fetchDoctors: async () => {
    set({ loading: true });
    try {
      // Simulated API call
      await new Promise(resolve => setTimeout(resolve, 1000));
      // In a real app, you would fetch data from your API here
      set({ loading: false });
    } catch (error) {
      set({ error: 'Failed to fetch referring doctors data', loading: false });
    }
  }
}));