import { env } from '../config/env';
import { Message } from '../types/message';

export class TelnyxService {
  private apiKey = env.TELNYX_API_KEY;
  private baseUrl = env.API_BASE_URL;

  async fetchMessages(): Promise<Message[]> {
    try {
      const response = await fetch(`${this.baseUrl}/messages`, {
        headers: {
          'Authorization': `Bearer ${this.apiKey}`,
          'Content-Type': 'application/json'
        }
      });

      if (!response.ok) {
        throw new Error('Failed to fetch messages from Telnyx');
      }

      const data = await response.json();
      
      return data.data.map(this.mapTelnyxMessage);
    } catch (error) {
      console.error('Error fetching Telnyx messages:', error);
      throw error;
    }
  }

  private mapTelnyxMessage(msg: any): Message {
    return {
      id: msg.id,
      type: 'message',
      subject: 'SMS Message',
      content: msg.text,
      sender: {
        id: msg.from.phone_number,
        name: msg.from.carrier,
        phone: msg.from.phone_number
      },
      recipient: {
        id: msg.to[0].phone_number,
        name: 'Dental Office',
        phone: msg.to[0].phone_number
      },
      timestamp: msg.sent_at || msg.received_at,
      status: 'unread',
      priority: 'normal'
    };
  }

  async sendMessage(to: string, content: string): Promise<void> {
    try {
      const response = await fetch(`${this.baseUrl}/messages`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${this.apiKey}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          from: '+13143262809', // Your Telnyx number
          to,
          text: content
        })
      });

      if (!response.ok) {
        throw new Error('Failed to send message via Telnyx');
      }
    } catch (error) {
      console.error('Error sending Telnyx message:', error);
      throw error;
    }
  }
}