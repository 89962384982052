import { MessagingService, MessageResponse } from '../types';
import { Message } from '../../../types/message';
import { env } from '../../../config/env';
import axios from 'axios';

export class TelnyxProvider implements MessagingService {
  private client;

  constructor() {
    if (!env.TELNYX_API_KEY) {
      throw new Error('Telnyx API key not configured');
    }

    this.client = axios.create({
      baseURL: env.API_BASE_URL,
      headers: {
        'Authorization': `Bearer ${env.TELNYX_API_KEY}`,
        'Content-Type': 'application/json'
      }
    });
  }

  async sendMessage(to: string, content: string): Promise<MessageResponse> {
    try {
      const response = await this.client.post('/messages', {
        from: '+13143262809',
        to,
        text: content
      });

      return {
        id: response.data.data.id,
        status: 'sent',
        provider: 'telnyx',
        timestamp: new Date().toISOString()
      };
    } catch (error) {
      console.error('Telnyx send error:', error);
      throw new Error('Failed to send message via Telnyx');
    }
  }

  async fetchMessages(): Promise<Message[]> {
    try {
      const response = await this.client.get('/messages');
      return response.data.data.map(this.mapTelnyxMessage);
    } catch (error) {
      console.error('Telnyx fetch error:', error);
      throw new Error('Failed to fetch messages from Telnyx');
    }
  }

  async getStatus(messageId: string): Promise<MessageResponse> {
    try {
      const response = await this.client.get(`/messages/${messageId}`);
      return {
        id: messageId,
        status: this.mapTelnyxStatus(response.data.data.status),
        provider: 'telnyx',
        timestamp: response.data.data.updated_at
      };
    } catch (error) {
      console.error('Telnyx status error:', error);
      throw new Error('Failed to get message status from Telnyx');
    }
  }

  private mapTelnyxMessage(msg: any): Message {
    return {
      id: msg.id.toString(),
      type: 'message',
      subject: 'SMS Message',
      content: msg.text,
      sender: {
        id: msg.from.phone_number,
        name: msg.from.carrier || 'Unknown',
        phone: msg.from.phone_number
      },
      recipient: {
        id: msg.to[0].phone_number,
        name: 'Dental Office',
        phone: msg.to[0].phone_number
      },
      timestamp: msg.sent_at || msg.received_at,
      status: 'unread',
      priority: 'normal'
    };
  }

  private mapTelnyxStatus(status: string): 'sent' | 'failed' | 'delivered' | 'read' {
    const statusMap: Record<string, 'sent' | 'failed' | 'delivered' | 'read'> = {
      'sent': 'sent',
      'delivered': 'delivered',
      'failed': 'failed',
      'read': 'read'
    };
    return statusMap[status] || 'sent';
  }
}