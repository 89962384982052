import { MessagingService, MessagingProvider, MessageResponse } from './types';
import { Message } from '../../types/message';
import { TelnyxProvider } from './providers/telnyxProvider';
import { communicationService } from '../api/communicationService';

export class MessagingService {
  private providers: Map<string, MessagingService>;
  
  constructor() {
    this.providers = new Map();
    this.initializeProviders();
  }

  private initializeProviders() {
    // Initialize providers safely
    try {
      // Only initialize if API key is available
      const telnyxProvider = new TelnyxProvider();
      this.providers.set('telnyx', telnyxProvider);
    } catch (error) {
      console.warn('Failed to initialize Telnyx provider:', error);
    }
  }

  getAvailableProviders(): MessagingProvider[] {
    return [
      {
        name: 'Telnyx',
        id: 'telnyx',
        icon: '📱',
        isEnabled: this.providers.has('telnyx')
      },
      {
        name: 'Twilio',
        id: 'twilio',
        icon: '💬',
        isEnabled: false
      },
      {
        name: 'SignalWire',
        id: 'signalwire',
        icon: '📡',
        isEnabled: false
      }
    ];
  }

  async sendMessage(providerId: string, to: string, content: string): Promise<MessageResponse> {
    const provider = this.providers.get(providerId);
    if (!provider) {
      throw new Error(`Provider ${providerId} not found or not enabled`);
    }

    try {
      const response = await provider.sendMessage(to, content);
      
      // Log the communication
      await communicationService.create({
        related_reference: to,
        related_type: 'outbound',
        channel: providerId,
        service_used: providerId,
        message_content: content,
        direction: 'outbound',
        status: response.status,
        timestamp: new Date().toISOString()
      });

      return response;
    } catch (error) {
      console.error(`Failed to send message via ${providerId}:`, error);
      throw error;
    }
  }

  async fetchMessages(providerId: string): Promise<Message[]> {
    const provider = this.providers.get(providerId);
    if (!provider) {
      throw new Error(`Provider ${providerId} not found or not enabled`);
    }

    try {
      const messages = await provider.fetchMessages();
      return messages.map(msg => ({
        ...msg,
        // Ensure all fields are serializable
        timestamp: msg.timestamp.toString(),
        sender: {
          ...msg.sender,
          id: msg.sender.id.toString()
        },
        recipient: {
          ...msg.recipient,
          id: msg.recipient.id.toString()
        }
      }));
    } catch (error) {
      console.error(`Failed to fetch messages from ${providerId}:`, error);
      throw error;
    }
  }

  async getMessageStatus(providerId: string, messageId: string): Promise<MessageResponse> {
    const provider = this.providers.get(providerId);
    if (!provider) {
      throw new Error(`Provider ${providerId} not found or not enabled`);
    }
    return provider.getStatus(messageId);
  }
}

// Export a singleton instance
export const messagingService = new MessagingService();