import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { SettingsState } from '../types/settings';

interface SettingsStore extends SettingsState {
  updateProfile: (data: Partial<SettingsState['profile']>) => Promise<void>;
  updateNotifications: (data: Partial<SettingsState['notifications']>) => Promise<void>;
  updateSecurity: (data: Partial<SettingsState['security']>) => Promise<void>;
  updatePreferences: (data: Partial<SettingsState['preferences']>) => Promise<void>;
}

export const useSettingsStore = create<SettingsStore>()(
  persist(
    (set) => ({
      profile: {
        id: '1',
        name: 'Dr. Sarah Johnson',
        email: 'sarah.johnson@example.com',
        role: 'doctor',
        avatar: 'https://ui-avatars.com/api/?name=Sarah+Johnson'
      },
      notifications: {
        email: true,
        sms: true,
        inApp: true,
        digest: 'daily'
      },
      security: {
        twoFactorEnabled: false,
        loginNotifications: true,
        trustedDevices: [
          {
            id: '1',
            name: 'Chrome on MacBook Pro',
            lastUsed: new Date().toISOString()
          }
        ]
      },
      preferences: {
        theme: 'system',
        language: 'en',
        timezone: 'America/Chicago',
        timeFormat: '12h'
      },
      loading: false,
      error: null,

      updateProfile: async (data) => {
        set((state) => ({
          profile: { ...state.profile, ...data }
        }));
      },

      updateNotifications: async (data) => {
        set((state) => ({
          notifications: { ...state.notifications, ...data }
        }));
      },

      updateSecurity: async (data) => {
        set((state) => ({
          security: { ...state.security, ...data }
        }));
      },

      updatePreferences: async (data) => {
        set((state) => ({
          preferences: { ...state.preferences, ...data }
        }));
      }
    }),
    {
      name: 'settings-storage'
    }
  )
);