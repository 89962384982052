import { api, handleApiError } from './config';
import { ApiResponse, Communication } from './types';

export const communicationService = {
  async create(communicationData: Partial<Communication>): Promise<number> {
    try {
      const response = await api.post<ApiResponse<void>>('/create/communications', communicationData);
      return response.data.id!;
    } catch (error) {
      return handleApiError(error);
    }
  },

  async getAll(): Promise<Communication[]> {
    try {
      const response = await api.get<ApiResponse<Communication[]>>('/read/communications');
      return response.data.data || [];
    } catch (error) {
      return handleApiError(error);
    }
  },

  async getById(id: number): Promise<Communication> {
    try {
      const response = await api.get<ApiResponse<Communication>>(`/read/communications/${id}`);
      return response.data.data!;
    } catch (error) {
      return handleApiError(error);
    }
  },

  async update(id: number, communicationData: Partial<Communication>): Promise<void> {
    try {
      await api.put(`/update/communications/${id}`, communicationData);
    } catch (error) {
      return handleApiError(error);
    }
  },

  async delete(id: number): Promise<void> {
    try {
      await api.delete(`/delete/communications/${id}`);
    } catch (error) {
      return handleApiError(error);
    }
  },

  async search(criteria: Partial<Communication>): Promise<Communication[]> {
    try {
      const response = await api.post<ApiResponse<Communication[]>>('/search/communications', criteria);
      return response.data.data || [];
    } catch (error) {
      return handleApiError(error);
    }
  }
};