import React from 'react';
import { Calendar, Filter } from 'lucide-react';
import { useAppointmentsStore } from '../../store/appointmentsStore';
import { AppointmentStatus, AppointmentType } from '../../types/appointment';

export function AppointmentFilters() {
  const { filters, setFilters } = useAppointmentsStore();

  const statusOptions: { value: AppointmentStatus | 'all'; label: string }[] = [
    { value: 'all', label: 'All Status' },
    { value: 'scheduled', label: 'Scheduled' },
    { value: 'confirmed', label: 'Confirmed' },
    { value: 'completed', label: 'Completed' },
    { value: 'cancelled', label: 'Cancelled' },
    { value: 'no-show', label: 'No Show' }
  ];

  const typeOptions: { value: AppointmentType | 'all'; label: string }[] = [
    { value: 'all', label: 'All Types' },
    { value: 'consultation', label: 'Consultation' },
    { value: 'checkup', label: 'Checkup' },
    { value: 'procedure', label: 'Procedure' },
    { value: 'follow-up', label: 'Follow-up' }
  ];

  return (
    <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-6">
      <div className="flex items-center space-x-2">
        <Calendar className="h-5 w-5 text-gray-400" />
        <select
          value={filters.view}
          onChange={(e) => setFilters({ view: e.target.value as 'month' | 'week' | 'day' })}
          className="bg-white dark:bg-gray-800 px-4 py-2 rounded-lg border border-gray-200 dark:border-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
        >
          <option value="month">Month View</option>
          <option value="week">Week View</option>
          <option value="day">Day View</option>
        </select>
      </div>

      <div className="flex items-center space-x-2">
        <Filter className="h-5 w-5 text-gray-400" />
        <select
          value={filters.status}
          onChange={(e) => setFilters({ status: e.target.value as AppointmentStatus | 'all' })}
          className="bg-white dark:bg-gray-800 px-4 py-2 rounded-lg border border-gray-200 dark:border-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
        >
          {statusOptions.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      </div>

      <select
        value={filters.type}
        onChange={(e) => setFilters({ type: e.target.value as AppointmentType | 'all' })}
        className="bg-white dark:bg-gray-800 px-4 py-2 rounded-lg border border-gray-200 dark:border-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
      >
        {typeOptions.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );
}