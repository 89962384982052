import React from 'react';
import { LoadingSpinner } from '../common/LoadingSpinner';
import { ErrorDisplay } from '../common/ErrorDisplay';

interface PageContainerProps {
  children: React.ReactNode;
  title: string;
  isLoading?: boolean;
  error?: string | null;
  onRetry?: () => void;
  actions?: React.ReactNode;
}

export function PageContainer({
  children,
  title,
  isLoading,
  error,
  onRetry,
  actions
}: PageContainerProps) {
  return (
    <div className="space-y-6 animate-fade-in min-h-[calc(100vh-4rem)]">
      <div className="flex items-center justify-between">
        <h1 className="text-2xl font-semibold">{title}</h1>
        {actions}
      </div>

      {isLoading ? (
        <LoadingSpinner />
      ) : error ? (
        <ErrorDisplay message={error} onRetry={onRetry} />
      ) : (
        children
      )}
    </div>
  );
}