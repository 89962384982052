import axios from 'axios';
import { parseApiError } from './utils/errorUtils';

const API_BASE_URL = 'https://api.nocodebackend.com';
const INSTANCE = '35557_referralomsdb';
const SECRET_KEY = '457fb2f02046325c433c364469e6b0485472dc2d05fbd5f94f9b1bf07c84';

export const api = axios.create({
  baseURL: API_BASE_URL,
  params: {
    Instance: INSTANCE,
    secret_key: SECRET_KEY
  }
});

api.interceptors.response.use(
  response => response,
  error => {
    const parsedError = parseApiError(error);
    console.error('API Error:', parsedError);
    throw parsedError;
  }
);

export const handleApiError = parseApiError;