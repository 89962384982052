import React, { useEffect, useState } from 'react';
import { MessageFilters } from '../components/messages/MessageFilters';
import { MessageList } from '../components/messages/MessageList';
import { MessageDetail } from '../components/messages/MessageDetail';
import { ProviderSelector } from '../components/messages/ProviderSelector';
import { useMessaging } from '../hooks/useMessaging';
import { Message } from '../types/message';
import { PageContainer } from '../components/layout/PageContainer';

export function Messages() {
  const {
    messages,
    loading,
    error,
    fetchMessages,
    sendMessage,
    getProviders
  } = useMessaging();

  const [selectedMessage, setSelectedMessage] = useState<Message | null>(null);
  const [selectedProvider, setSelectedProvider] = useState('telnyx');
  const providers = getProviders();

  useEffect(() => {
    if (selectedProvider) {
      fetchMessages(selectedProvider);
    }
  }, [selectedProvider, fetchMessages]);

  const handleSendMessage = async (to: string, content: string) => {
    try {
      await sendMessage(selectedProvider, to, content);
      fetchMessages(selectedProvider);
    } catch (err) {
      console.error('Failed to send message:', err);
    }
  };

  const handleProviderSelect = (providerId: string) => {
    setSelectedProvider(providerId);
    setSelectedMessage(null);
  };

  return (
    <PageContainer
      title="Messages"
      isLoading={loading}
      error={error}
      onRetry={() => fetchMessages(selectedProvider)}
    >
      <ProviderSelector
        providers={providers}
        selectedProvider={selectedProvider}
        onSelect={handleProviderSelect}
      />
      
      <MessageFilters />
      
      {selectedMessage ? (
        <MessageDetail
          message={selectedMessage}
          onBack={() => setSelectedMessage(null)}
          onSendMessage={handleSendMessage}
        />
      ) : (
        <MessageList
          messages={messages}
          onSelect={setSelectedMessage}
          onSendMessage={handleSendMessage}
        />
      )}
    </PageContainer>
  );
}