import React from 'react';
import { ArrowLeft, Archive, Trash2, Mail, MessageSquare, Voicemail, Play } from 'lucide-react';
import { Message } from '../../types/message';
import { formatDistanceToNow } from 'date-fns';

interface MessageDetailProps {
  message: Message;
  onBack: () => void;
  onArchive: (id: string) => void;
  onDelete: (id: string) => void;
}

export function MessageDetail({ message, onBack, onArchive, onDelete }: MessageDetailProps) {
  const getMessageIcon = (type: Message['type']) => {
    switch (type) {
      case 'email':
        return <Mail className="h-5 w-5 text-blue-500" />;
      case 'voicemail':
        return <Voicemail className="h-5 w-5 text-purple-500" />;
      default:
        return <MessageSquare className="h-5 w-5 text-green-500" />;
    }
  };

  return (
    <div className="bg-white dark:bg-gray-800 rounded-lg shadow-md p-6">
      <div className="flex items-center justify-between mb-6">
        <button
          onClick={onBack}
          className="flex items-center text-gray-600 dark:text-gray-400 hover:text-gray-900 dark:hover:text-white"
        >
          <ArrowLeft className="h-5 w-5 mr-2" />
          Back to Messages
        </button>
        
        <div className="flex items-center space-x-2">
          <button
            onClick={() => onArchive(message.id)}
            className="p-2 text-gray-600 dark:text-gray-400 hover:text-gray-900 dark:hover:text-white rounded-lg"
          >
            <Archive className="h-5 w-5" />
          </button>
          <button
            onClick={() => onDelete(message.id)}
            className="p-2 text-gray-600 dark:text-gray-400 hover:text-red-500 rounded-lg"
          >
            <Trash2 className="h-5 w-5" />
          </button>
        </div>
      </div>

      <div className="space-y-6">
        <div className="flex items-start space-x-4">
          <div className="flex-shrink-0">
            {message.sender.avatar ? (
              <img
                src={message.sender.avatar}
                alt={message.sender.name}
                className="h-12 w-12 rounded-full"
              />
            ) : (
              <div className="h-12 w-12 rounded-full bg-gray-200 dark:bg-gray-700 flex items-center justify-center">
                {getMessageIcon(message.type)}
              </div>
            )}
          </div>
          
          <div className="flex-1">
            <h2 className="text-xl font-semibold mb-1">{message.subject}</h2>
            <div className="text-sm text-gray-600 dark:text-gray-400">
              <p>From: {message.sender.name} {message.sender.email && `<${message.sender.email}>`}</p>
              <p>To: {message.recipient.name} {message.recipient.email && `<${message.recipient.email}>`}</p>
              <p>{formatDistanceToNow(new Date(message.timestamp), { addSuffix: true })}</p>
            </div>
          </div>
        </div>

        {message.type === 'voicemail' && message.duration && (
          <div className="bg-gray-100 dark:bg-gray-700 rounded-lg p-4">
            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <Play className="h-5 w-5 mr-2" />
                <span>Voice Message ({Math.floor(message.duration / 60)}:{(message.duration % 60).toString().padStart(2, '0')})</span>
              </div>
              {message.transcription && (
                <button className="text-sm text-blue-500 hover:text-blue-600">
                  View Transcription
                </button>
              )}
            </div>
          </div>
        )}

        <div className="prose dark:prose-invert max-w-none">
          {message.content}
        </div>

        {message.attachments && message.attachments.length > 0 && (
          <div className="border-t border-gray-200 dark:border-gray-700 pt-4">
            <h3 className="text-sm font-semibold mb-2">Attachments</h3>
            <div className="space-y-2">
              {message.attachments.map((attachment) => (
                <div
                  key={attachment.id}
                  className="flex items-center justify-between bg-gray-50 dark:bg-gray-700 rounded-lg p-3"
                >
                  <span>{attachment.name}</span>
                  <button className="text-sm text-blue-500 hover:text-blue-600">
                    Download
                  </button>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}