import { create } from 'zustand';
import { Patient, PatientFilters } from '../types/patient';

interface PatientsState {
  patients: Patient[];
  filters: PatientFilters;
  loading: boolean;
  error: string | null;
  setFilters: (filters: Partial<PatientFilters>) => void;
  fetchPatients: () => Promise<void>;
}

export const usePatientsStore = create<PatientsState>((set) => ({
  patients: [
    {
      id: '1',
      name: 'John Smith',
      email: 'john.smith@email.com',
      phone: '(555) 123-4567',
      dateOfBirth: '1985-06-15',
      lastVisit: '2024-02-15',
      nextAppointment: '2024-03-20',
      status: 'active',
      insuranceProvider: 'Blue Cross',
      notes: 'Regular checkups, no major issues.'
    },
    {
      id: '2',
      name: 'Emma Johnson',
      email: 'emma.j@email.com',
      phone: '(555) 987-6543',
      dateOfBirth: '1990-03-22',
      lastVisit: '2024-01-30',
      nextAppointment: null,
      status: 'inactive',
      insuranceProvider: 'Aetna',
      notes: 'Needs follow-up on crown procedure.'
    }
  ],
  filters: {
    status: 'all',
    search: '',
    insuranceProvider: 'all'
  },
  loading: false,
  error: null,
  setFilters: (newFilters) =>
    set((state) => ({
      filters: { ...state.filters, ...newFilters }
    })),
  fetchPatients: async () => {
    set({ loading: true });
    try {
      // Simulated API call
      await new Promise(resolve => setTimeout(resolve, 1000));
      // In a real app, you would fetch data from your API here
      set({ loading: false });
    } catch (error) {
      set({ error: 'Failed to fetch patients data', loading: false });
    }
  }
}));