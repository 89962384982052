import React from 'react';
import { LucideIcon } from 'lucide-react';

interface StatCardProps {
  title: string;
  value: string | number;
  icon: LucideIcon;
  color: string;
  change?: {
    value: number;
    type: 'increase' | 'decrease';
  };
}

export function StatCard({ title, value, icon: Icon, color, change }: StatCardProps) {
  return (
    <div className="bg-white dark:bg-gray-800 rounded-lg shadow-md p-6">
      <div className="flex items-center justify-between mb-4">
        <h2 className="text-lg font-semibold">{title}</h2>
        <Icon className={`h-6 w-6 ${color}`} />
      </div>
      <p className={`text-3xl font-bold ${color}`}>{value}</p>
      {change && (
        <div className="mt-2 flex items-center text-sm">
          <span className={change.type === 'increase' ? 'text-green-500' : 'text-red-500'}>
            {change.type === 'increase' ? '↑' : '↓'} {Math.abs(change.value)}%
          </span>
          <span className="text-gray-500 dark:text-gray-400 ml-2">vs last month</span>
        </div>
      )}
    </div>
  );
}