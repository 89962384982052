import { create } from 'zustand';

interface User {
  id: string;
  name: string;
  email: string;
  role: 'admin' | 'doctor' | 'staff';
}

interface AuthState {
  user: User | null;
  isAuthenticated: boolean;
  loading: boolean;
  error: string | null;
  login: (credentials: { email: string; password: string }) => Promise<void>;
  logout: () => Promise<void>;
}

export const useAuthStore = create<AuthState>((set) => ({
  user: null,
  isAuthenticated: false,
  loading: false,
  error: null,
  login: async (credentials: { email: string; password: string }) => {
    set({ loading: true, error: null });
    try {
      // Simulated login
      await new Promise(resolve => setTimeout(resolve, 1000));
      set({
        user: {
          id: '1',
          name: 'Dr. Sarah Johnson',
          email: credentials.email,
          role: 'admin'
        },
        isAuthenticated: true,
        loading: false
      });
    } catch (error) {
      set({ error: 'Invalid credentials', loading: false });
    }
  },
  logout: async () => {
    try {
      set({ loading: true });
      // Simulate API call to logout
      await new Promise(resolve => setTimeout(resolve, 500));
      set({
        user: null,
        isAuthenticated: false,
        loading: false,
        error: null
      });
    } catch (error) {
      set({ error: 'Failed to logout', loading: false });
    }
  }
}));