import React from 'react';
import { Mail, MessageSquare, Voicemail, Archive, Trash2, Send } from 'lucide-react';
import { Message } from '../../types/message';
import { formatDistanceToNow } from 'date-fns';

interface MessageListProps {
  messages: Message[];
  onSelect: (message: Message) => void;
  onSendMessage: (to: string, content: string) => void;
}

export function MessageList({ messages, onSelect, onSendMessage }: MessageListProps) {
  const [newMessage, setNewMessage] = React.useState({
    to: '',
    content: ''
  });

  const handleSend = async (e: React.FormEvent) => {
    e.preventDefault();
    if (newMessage.to && newMessage.content) {
      try {
        await onSendMessage(newMessage.to, newMessage.content);
        setNewMessage({ to: '', content: '' });
      } catch (error) {
        console.error('Failed to send message:', error);
      }
    }
  };

  return (
    <div className="space-y-4">
      <form onSubmit={handleSend} className="bg-white dark:bg-gray-800 rounded-lg shadow-md p-4 space-y-4">
        <div>
          <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
            To:
          </label>
          <input
            type="text"
            value={newMessage.to}
            onChange={(e) => setNewMessage(prev => ({ ...prev, to: e.target.value }))}
            placeholder="+1234567890"
            className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500 dark:bg-gray-700 dark:border-gray-600"
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
            Message:
          </label>
          <textarea
            value={newMessage.content}
            onChange={(e) => setNewMessage(prev => ({ ...prev, content: e.target.value }))}
            placeholder="Type your message..."
            className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500 dark:bg-gray-700 dark:border-gray-600"
            rows={3}
          />
        </div>
        <button
          type="submit"
          className="w-full flex items-center justify-center px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors"
          disabled={!newMessage.to || !newMessage.content}
        >
          <Send className="h-5 w-5 mr-2" />
          Send Message
        </button>
      </form>

      <div className="space-y-2">
        {messages.length === 0 ? (
          <div className="text-center py-12 bg-white dark:bg-gray-800 rounded-lg">
            <MessageSquare className="h-12 w-12 mx-auto text-gray-400 mb-4" />
            <h3 className="text-lg font-medium text-gray-900 dark:text-white">No messages found</h3>
            <p className="text-gray-500 dark:text-gray-400">Start a conversation by sending a message</p>
          </div>
        ) : (
          messages.map((message) => (
            <div
              key={message.id}
              className="group bg-white dark:bg-gray-800 rounded-lg shadow-sm p-4 cursor-pointer transition-all hover:shadow-md"
              onClick={() => onSelect(message)}
            >
              <div className="flex items-start justify-between">
                <div className="flex items-start space-x-4">
                  <div className="flex-shrink-0">
                    {message.sender.avatar ? (
                      <img
                        src={message.sender.avatar}
                        alt={message.sender.name}
                        className="h-10 w-10 rounded-full"
                      />
                    ) : (
                      <div className="h-10 w-10 rounded-full bg-gray-200 dark:bg-gray-700 flex items-center justify-center">
                        <MessageSquare className="h-5 w-5 text-gray-500" />
                      </div>
                    )}
                  </div>

                  <div className="flex-1 min-w-0">
                    <div className="flex items-center justify-between">
                      <h3 className="text-sm font-semibold truncate">
                        {message.sender.name}
                        {message.sender.phone && (
                          <span className="ml-2 text-sm text-gray-500">
                            ({message.sender.phone})
                          </span>
                        )}
                      </h3>
                      <span className="text-xs text-gray-500 dark:text-gray-400">
                        {formatDistanceToNow(new Date(message.timestamp), { addSuffix: true })}
                      </span>
                    </div>
                    <p className="text-sm font-medium">{message.subject}</p>
                    <p className="text-sm text-gray-600 dark:text-gray-400 line-clamp-1">
                      {message.content}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );
}