import React from 'react';
import { Search } from 'lucide-react';
import { usePatientsStore } from '../../store/patientsStore';

export function PatientFilters() {
  const { filters, setFilters } = usePatientsStore();

  return (
    <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-6">
      <div className="relative">
        <input
          type="text"
          placeholder="Search patients..."
          value={filters.search}
          onChange={(e) => setFilters({ search: e.target.value })}
          className="w-full bg-white dark:bg-gray-800 px-4 py-2 pl-10 rounded-lg border border-gray-200 dark:border-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
        <Search className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
      </div>

      <select
        value={filters.status}
        onChange={(e) => setFilters({ status: e.target.value })}
        className="bg-white dark:bg-gray-800 px-4 py-2 rounded-lg border border-gray-200 dark:border-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
      >
        <option value="all">All Status</option>
        <option value="active">Active</option>
        <option value="inactive">Inactive</option>
      </select>

      <select
        value={filters.insuranceProvider}
        onChange={(e) => setFilters({ insuranceProvider: e.target.value })}
        className="bg-white dark:bg-gray-800 px-4 py-2 rounded-lg border border-gray-200 dark:border-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
      >
        <option value="all">All Insurance Providers</option>
        <option value="Blue Cross">Blue Cross</option>
        <option value="Aetna">Aetna</option>
        <option value="Cigna">Cigna</option>
      </select>
    </div>
  );
}