import React from 'react';
import { Search } from 'lucide-react';
import { useReferringDoctorsStore } from '../../store/referringDoctorsStore';

export function ReferringDoctorFilters() {
  const { filters, setFilters } = useReferringDoctorsStore();

  return (
    <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-6">
      <div className="relative">
        <input
          type="text"
          placeholder="Search doctors..."
          value={filters.search}
          onChange={(e) => setFilters({ search: e.target.value })}
          className="w-full bg-white dark:bg-gray-800 px-4 py-2 pl-10 rounded-lg border border-gray-200 dark:border-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
        <Search className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
      </div>

      <select
        value={filters.specialty}
        onChange={(e) => setFilters({ specialty: e.target.value })}
        className="bg-white dark:bg-gray-800 px-4 py-2 rounded-lg border border-gray-200 dark:border-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
      >
        <option value="all">All Specialties</option>
        <option value="Orthodontics">Orthodontics</option>
        <option value="Periodontics">Periodontics</option>
        <option value="Endodontics">Endodontics</option>
        <option value="Oral Surgery">Oral Surgery</option>
      </select>

      <select
        value={filters.status}
        onChange={(e) => setFilters({ status: e.target.value })}
        className="bg-white dark:bg-gray-800 px-4 py-2 rounded-lg border border-gray-200 dark:border-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
      >
        <option value="all">All Status</option>
        <option value="active">Active</option>
        <option value="inactive">Inactive</option>
      </select>
    </div>
  );
}