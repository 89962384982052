import React, { useEffect } from 'react';
import { CalendarPlus } from 'lucide-react';
import { AppointmentFilters } from '../components/appointments/AppointmentFilters';
import { AppointmentCalendar } from '../components/appointments/AppointmentCalendar';
import { useAppointmentsStore } from '../store/appointmentsStore';

export function Appointments() {
  const { loading, error, fetchAppointments } = useAppointmentsStore();

  useEffect(() => {
    fetchAppointments();
  }, [fetchAppointments]);

  if (loading) {
    return (
      <div className="flex items-center justify-center h-full">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-center py-12">
        <p className="text-red-500">{error}</p>
        <button
          onClick={() => fetchAppointments()}
          className="mt-4 px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors"
        >
          Retry
        </button>
      </div>
    );
  }

  return (
    <div className="space-y-6 animate-fade-in">
      <div className="flex items-center justify-between">
        <h1 className="text-2xl font-semibold">Appointments</h1>
        <button className="flex items-center px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors">
          <CalendarPlus className="h-5 w-5 mr-2" />
          New Appointment
        </button>
      </div>

      <AppointmentFilters />
      <AppointmentCalendar />
    </div>
  );
}