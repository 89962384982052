import { create } from 'zustand';

interface AnalyticsState {
  stats: {
    totalPatients: number;
    activeReferrals: number;
    todayAppointments: number;
  };
  patientTrend: Array<{ date: string; value: number }>;
  referralTrend: Array<{ date: string; value: number }>;
  loading: boolean;
  error: string | null;
  fetchAnalytics: () => Promise<void>;
}

export const useAnalyticsStore = create<AnalyticsState>((set) => ({
  stats: {
    totalPatients: 1234,
    activeReferrals: 56,
    todayAppointments: 8
  },
  patientTrend: Array.from({ length: 7 }, (_, i) => ({
    date: new Date(Date.now() - i * 24 * 60 * 60 * 1000).toLocaleDateString('en-US', { month: 'short', day: 'numeric' }),
    value: Math.floor(Math.random() * 50) + 100
  })).reverse(),
  referralTrend: Array.from({ length: 7 }, (_, i) => ({
    date: new Date(Date.now() - i * 24 * 60 * 60 * 1000).toLocaleDateString('en-US', { month: 'short', day: 'numeric' }),
    value: Math.floor(Math.random() * 20) + 10
  })).reverse(),
  loading: false,
  error: null,
  fetchAnalytics: async () => {
    set({ loading: true });
    try {
      // Simulated API call
      await new Promise(resolve => setTimeout(resolve, 1000));
      // In a real app, you would fetch data from your API here
      set({ loading: false });
    } catch (error) {
      set({ error: 'Failed to fetch analytics data', loading: false });
    }
  }
}));