import React, { useEffect } from 'react';
import { UserPlus } from 'lucide-react';
import { PatientFilters } from '../components/patients/PatientFilters';
import { PatientList } from '../components/patients/PatientList';
import { usePatientsStore } from '../store/patientsStore';

export function Patients() {
  const { patients, loading, error, fetchPatients, filters } = usePatientsStore();

  useEffect(() => {
    fetchPatients();
  }, [fetchPatients]);

  const filteredPatients = patients.filter((patient) => {
    const matchesSearch = patient.name.toLowerCase().includes(filters.search.toLowerCase()) ||
      patient.email.toLowerCase().includes(filters.search.toLowerCase());
    const matchesStatus = filters.status === 'all' || patient.status === filters.status;
    const matchesInsurance = filters.insuranceProvider === 'all' || 
      patient.insuranceProvider === filters.insuranceProvider;

    return matchesSearch && matchesStatus && matchesInsurance;
  });

  if (loading) {
    return (
      <div className="flex items-center justify-center h-full">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-center py-12">
        <p className="text-red-500">{error}</p>
        <button
          onClick={() => fetchPatients()}
          className="mt-4 px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors"
        >
          Retry
        </button>
      </div>
    );
  }

  return (
    <div className="space-y-6 animate-fade-in">
      <div className="flex items-center justify-between">
        <h1 className="text-2xl font-semibold">Patients</h1>
        <button className="flex items-center px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors">
          <UserPlus className="h-5 w-5 mr-2" />
          Add New Patient
        </button>
      </div>

      <PatientFilters />
      <PatientList patients={filteredPatients} />
    </div>
  );
}