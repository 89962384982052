import React from 'react';
import {
  useNavigate,
  useLocation,
  Link
} from 'react-router-dom';
import {
  BarChart3, Users, UserPlus, Calendar, MessageSquare, Mail, 
  Send, Settings, LogOut 
} from 'lucide-react';
import { useAuthStore } from '../../store/authStore';

const navItems = [
  { icon: BarChart3, label: 'Analytics', href: '/analytics' },
  { icon: Users, label: 'Patients', href: '/patients' },
  { icon: UserPlus, label: 'Referring Doctors', href: '/referring-doctors' },
  { icon: Calendar, label: 'Appointments', href: '/appointments' },
  { icon: MessageSquare, label: 'Messages', href: '/messages' },
  { icon: Send, label: 'Newsletters', href: '/newsletters' },
  { icon: Settings, label: 'Settings', href: '/settings' },
];

export function Sidebar() {
  const [collapsed, setCollapsed] = React.useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { logout } = useAuthStore();
  
  const handleLogout = async () => {
    try {
      await logout();
      navigate('/analytics');
      window.location.reload(); // Force reload to clear any cached state
    } catch (error) {
      console.error('Failed to logout:', error);
    }
  };

  return (
    <aside className={`bg-gray-900 text-white h-screen transition-all duration-300 ${
      collapsed ? 'w-20' : 'w-64'
    }`}>
      <div className="p-4">
        <h1 className={`text-xl font-bold ${collapsed ? 'hidden' : 'block'}`}>
          DentalReferrals.net
        </h1>
      </div>
      
      <nav className="mt-8">
        {navItems.map((item) => (
          <Link
            key={item.href}
            to={item.href}
            className={`flex items-center px-4 py-3 text-gray-300 hover:bg-gray-800 hover:text-white transition-colors ${
              location.pathname === item.href ? 'bg-gray-800 text-white' : ''
            }`}
          >
            <item.icon className="w-6 h-6" />
            <span className={`ml-4 ${collapsed ? 'hidden' : 'block'}`}>
              {item.label}
            </span>
          </Link>
        ))}
        
        <button 
          onClick={handleLogout}
          className="flex items-center px-4 py-3 text-gray-300 hover:bg-gray-800 hover:text-white transition-colors w-full mt-8"
        >
          <LogOut className="w-6 h-6" />
          <span className={`ml-4 ${collapsed ? 'hidden' : 'block'}`}>
            Logout
          </span>
        </button>
      </nav>
      
      <button
        onClick={() => setCollapsed(!collapsed)}
        className="absolute bottom-4 left-4 text-gray-300 hover:text-white"
      >
        {collapsed ? '→' : '←'}
      </button>
    </aside>
  );
}