import React from 'react';
import { User } from 'lucide-react';

export function UserMenu() {
  return (
    <div className="flex items-center space-x-3">
      <div className="text-right mr-2">
        <p className="text-sm font-medium">Dr. Sarah Johnson</p>
        <p className="text-xs text-gray-400">Administrator</p>
      </div>
      <button className="rounded-full bg-gray-700 p-1 hover:bg-gray-600 transition-colors">
        <User className="h-6 w-6" />
      </button>
    </div>
  );
}