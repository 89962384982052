import React, { useState } from 'react';
import { X, Calendar, Users, Send } from 'lucide-react';
import { Newsletter } from '../../types/newsletter';
import { useNewsletterStore } from '../../store/newsletterStore';

interface NewsletterEditorProps {
  newsletter?: Newsletter | null;
  onClose: () => void;
}

export function NewsletterEditor({ newsletter, onClose }: NewsletterEditorProps) {
  const { createNewsletter, updateNewsletter } = useNewsletterStore();
  const [formData, setFormData] = useState({
    title: newsletter?.title || '',
    subject: newsletter?.subject || '',
    content: newsletter?.content || '',
    scheduledFor: newsletter?.scheduledFor || '',
    recipients: newsletter?.recipients || { groups: [], count: 0 }
  });

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (newsletter) {
      await updateNewsletter(newsletter.id, formData);
    } else {
      await createNewsletter(formData);
    }
    onClose();
  };

  return (
    <div className="bg-white dark:bg-gray-800 rounded-lg shadow-md">
      <div className="flex items-center justify-between p-6 border-b border-gray-200 dark:border-gray-700">
        <h2 className="text-xl font-semibold">
          {newsletter ? 'Edit Newsletter' : 'Create Newsletter'}
        </h2>
        <button 
          onClick={onClose}
          className="text-gray-500 hover:text-gray-700 dark:hover:text-gray-300"
        >
          <X className="h-6 w-6" />
        </button>
      </div>

      <form onSubmit={handleSubmit} className="p-6 space-y-6">
        <div>
          <label className="block text-sm font-medium mb-2">Title</label>
          <input
            type="text"
            value={formData.title}
            onChange={(e) => setFormData({ ...formData, title: e.target.value })}
            className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500 dark:bg-gray-700 dark:border-gray-600"
            placeholder="Newsletter title"
            required
          />
        </div>

        <div>
          <label className="block text-sm font-medium mb-2">Subject</label>
          <input
            type="text"
            value={formData.subject}
            onChange={(e) => setFormData({ ...formData, subject: e.target.value })}
            className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500 dark:bg-gray-700 dark:border-gray-600"
            placeholder="Email subject line"
            required
          />
        </div>

        <div>
          <label className="block text-sm font-medium mb-2">Content</label>
          <div className="border rounded-lg dark:border-gray-600">
            <textarea
              value={formData.content}
              onChange={(e) => setFormData({ ...formData, content: e.target.value })}
              className="w-full px-3 py-2 focus:ring-2 focus:ring-blue-500 border-none dark:bg-gray-700"
              rows={10}
              placeholder="Write your newsletter content..."
              required
            />
          </div>
        </div>

        <div className="grid grid-cols-2 gap-6">
          <div>
            <label className="block text-sm font-medium mb-2">Schedule Date</label>
            <div className="relative">
              <Calendar className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
              <input
                type="datetime-local"
                value={formData.scheduledFor}
                onChange={(e) => setFormData({ ...formData, scheduledFor: e.target.value })}
                className="w-full pl-10 px-3 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500 dark:bg-gray-700 dark:border-gray-600"
              />
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium mb-2">Recipients</label>
            <div className="relative">
              <Users className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
              <select
                multiple
                value={formData.recipients.groups}
                onChange={(e) => setFormData({
                  ...formData,
                  recipients: {
                    groups: Array.from(e.target.selectedOptions, option => option.value),
                    count: formData.recipients.count
                  }
                })}
                className="w-full pl-10 px-3 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500 dark:bg-gray-700 dark:border-gray-600"
              >
                <option value="active-patients">Active Patients</option>
                <option value="inactive-patients">Inactive Patients</option>
                <option value="referring-doctors">Referring Doctors</option>
                <option value="newsletter-subscribers">Newsletter Subscribers</option>
              </select>
            </div>
          </div>
        </div>

        <div className="flex justify-end space-x-4 pt-6">
          <button
            type="button"
            onClick={onClose}
            className="px-4 py-2 text-gray-700 bg-gray-100 rounded-lg hover:bg-gray-200 dark:bg-gray-700 dark:text-gray-200 dark:hover:bg-gray-600"
          >
            Cancel
          </button>
          <button
            type="submit"
            className="flex items-center px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600"
          >
            <Send className="h-5 w-5 mr-2" />
            {formData.scheduledFor ? 'Schedule' : 'Send Now'}
          </button>
        </div>
      </form>
    </div>
  );
}