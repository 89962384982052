import { useState, useCallback, useEffect } from 'react';
import { messagingService } from '../services/messaging/messagingService';
import { Message } from '../types/message';
import { MessageResponse, MessagingProvider } from '../services/messaging/types';
import { useLoadingState } from './useLoadingState';

export function useMessaging() {
  const [messages, setMessages] = useState<Message[]>([]);
  const { isLoading: loading, error, setError, startLoading, stopLoading } = useLoadingState();
  const [providers] = useState<MessagingProvider[]>(messagingService.getAvailableProviders());

  const fetchMessages = useCallback(async (providerId: string) => {
    startLoading();
    try {
      const fetchedMessages = await messagingService.fetchMessages(providerId);
      setMessages(fetchedMessages);
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Failed to fetch messages');
      console.error('Error fetching messages:', err);
    } finally {
      stopLoading();
    }
  }, [startLoading, stopLoading, setError]);

  const sendMessage = useCallback(async (
    providerId: string,
    to: string,
    content: string
  ): Promise<MessageResponse> => {
    startLoading();
    try {
      const response = await messagingService.sendMessage(providerId, to, content);
      // Refresh messages after sending
      await fetchMessages(providerId);
      return response;
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Failed to send message');
      throw err;
    } finally {
      stopLoading();
    }
  }, [fetchMessages, startLoading, stopLoading, setError]);

  const getProviders = useCallback((): MessagingProvider[] => {
    return providers;
  }, [providers]);

  // Auto-refresh messages every 30 seconds
  useEffect(() => {
    const selectedProvider = providers.find(p => p.isEnabled)?.id;
    if (selectedProvider) {
      const interval = setInterval(() => {
        fetchMessages(selectedProvider);
      }, 30000);
      return () => clearInterval(interval);
    }
  }, [providers, fetchMessages]);

  return {
    messages,
    loading,
    error,
    fetchMessages,
    sendMessage,
    getProviders
  };
}